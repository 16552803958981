import axios, { type AxiosResponse, type AxiosRequestConfig } from 'axios'
import {
  ClientError,
  type GraphQLRequestContext,
  type GraphQLResponse,
  type Variables,
} from '@/features/shared/utils/dataFetching/reactQuery/graphqlTypes'
import { isOnServer } from '@shared/constants/util'
import { isErrorMessage } from '@/features/shared/utils/guards/error'
import { type QueryFunctionContext } from '@tanstack/react-query'
import { type GetServerSidePropsContext } from 'next'
import { getAuthClient } from '@/features/authentication/utils/authentication/client'
import { parse, getOperationAST } from 'graphql'
import {
  isProduction,
  isRunningTests,
} from '@/features/shared/utils/environment'
import { logDataFetchingErrors } from '@/features/shared/utils/dataFetching/utils'
import { getCommonRequestHeaders } from '@/features/shared/utils/dataFetching/requestHeaders'
import Bugsnag from '@bugsnag/js'

const NO_TOKEN_ERROR = 'No Token for request'

type RequestMeta = {
  baseURL: string | undefined
  ssrContext: GetServerSidePropsContext | undefined
  token: string
}

type GetRequestLocationMetaParams = {
  token?: string
  ssrContext: RequestMeta['ssrContext']
}

export type GetRequestLocation = (meta?: GetRequestLocationMetaParams) => {
  baseURL: RequestMeta['baseURL']
  url: string
}

const getRequestLocation: GetRequestLocation = () => {
  return {
    baseURL: process.env.NEXT_PUBLIC_SHIPT_API_GATEWAY_URL!,
    url: '/graphql-router',
  }
}

// Expose this if we need to have different implementations
const getToken = async (ssrContext: RequestMeta['ssrContext']) => {
  let token: string | undefined = ''
  try {
    token = await getAuthClient(ssrContext).getAccessToken({ ssrContext })
  } catch {
    // porting existing behavior for now; do nothing if there is an error getting access token
    // api call will fail; error from failed fetch will log
  }
  // graphql doesn't work on client today without an auth token, so prevent calls on a holistic level
  // NOTE: please don't remove this until we have a way to accept non auth requests!
  // https://app.shortcut.com/shipt/story/393817
  if (!token) {
    throw new Error(NO_TOKEN_ERROR)
  }
  return token
}

type RequestMetaHelpers = {
  getRequestLocation?: typeof getRequestLocation
  callLocation?: string
}

type GQLRequestOptions<TVariables> = {
  ssrContext?: GetServerSidePropsContext
  data: GraphQLRequestContext<TVariables>
  requestHelpers?: RequestMetaHelpers
}

export enum GQL_TIMEOUTS {
  onClient = 30_000,
  onServer = 2_000,
}

export const getGqlRequestConfig = async <TVariables extends Variables>({
  ssrContext,
  requestHelpers,
  data,
}: GQLRequestOptions<TVariables>): Promise<
  AxiosRequestConfig<GraphQLRequestContext<TVariables>>
> => {
  const token = await getToken(ssrContext)

  const getLocation = requestHelpers?.getRequestLocation ?? getRequestLocation
  const location = getLocation({ token, ssrContext })
  const headers: AxiosRequestConfig['headers'] = {
    ...getCommonRequestHeaders({ ssrContext, isGQL: true }),
    authorization: token ? `Bearer ${token}` : '',
  }
  return {
    ...location,
    method: 'POST',
    headers,
    data,
    timeout: isOnServer() ? GQL_TIMEOUTS.onServer : GQL_TIMEOUTS.onClient,
  }
}

function leaveCustomGraphqlBreadcrumb({
  operationName,
  url,
  hasError,
}: {
  operationName: string
  url: string | undefined
  hasError: boolean
}) {
  if (isOnServer()) return
  Bugsnag.leaveBreadcrumb(
    `GraphQL Request ${hasError ? 'Failed' : 'Succeeded'}`,
    { operationName, request: url },
    'request'
  )
}

// we don't want response data in bugsnag for PII reasons, specifically due to the nature
// of the BaseUser query
function stripDataFromGraphqlResponse(
  response: AxiosResponse<GraphQLResponse<unknown>>
) {
  return {
    ...response,
    data: {
      ...response.data,
      data: {},
    },
  }
}

function getGraphqlResponseState<TData>(
  response: AxiosResponse<GraphQLResponse<TData>, unknown>,
  options?: GraphQLRequestOptions<TData>
): 'ok' | 'fatal' | 'allowed' {
  if (!response.data.data) {
    return 'fatal'
  } else if (
    response.data.errors &&
    !options?.onError?.(response.data) &&
    // Critical GQL errors do not want this code to throw if we have a gql error
    // we only want to throw if data is null
    (options?.throwOnGqlError ?? true)
  ) {
    return 'fatal'
  } else if (response.data.errors) {
    return 'allowed'
  }
  return 'ok'
}

type GraphQLRequestOptions<TData> = {
  /*
   * onError: allow for ignoring GraphQL Error, based on response
   * true: ignore error
   * false: throw error
   * */
  onError?: (response: GraphQLResponse<TData>) => boolean
  throwOnGqlError?: boolean
}
export const makeFetchGraphQL = (requestHelpers?: RequestMetaHelpers) => {
  return <TData, TVariables extends Variables>(
    query: string,
    variables?: TVariables,
    // @graphql-codegen/typescript-react-query 4.0 is adding an additional options
    // argument that we do not use
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _options?: RequestInit['headers']
  ): ((
    context?: QueryFunctionContext,
    options?: GraphQLRequestOptions<TData>
  ) => Promise<TData>) => {
    const operationName =
      getOperationAST(parse(query))?.name?.value ?? 'UNKNOWN_OPERATION'
    return async (context, options) => {
      // ssrContext is set by getQueryClient(ssrContext) in SSR methods. It only
      // works for queries, not mutations at SSR time
      const ssrContext = context?.meta?.ssrContext

      const requestBody: GraphQLRequestContext<TVariables> = {
        query,
        variables,
        operationName,
      }

      let config: AxiosRequestConfig<GraphQLRequestContext<TVariables>>

      try {
        config = await getGqlRequestConfig<TVariables>({
          ssrContext,
          requestHelpers,
          data: requestBody,
        })
      } catch (error) {
        if (isErrorMessage(error) && error.message === NO_TOKEN_ERROR) {
          // This will only be surfaced to engineers to help them debug potential auth issues at SSR time
          // Doing this prevents console log noise on not authed pages
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          return { error: NO_TOKEN_ERROR } as unknown as TData
        }
        throw error
      }
      try {
        const response = await axios.request<GraphQLResponse<TData>>(config)
        const responseState = getGraphqlResponseState(response, options)

        if (responseState === 'fatal' || !response.data.data) {
          leaveCustomGraphqlBreadcrumb({
            operationName,
            url: config.url,
            hasError: true,
          })
          throw new ClientError(
            stripDataFromGraphqlResponse(response),
            requestBody
          )
        }

        if (responseState === 'allowed') {
          logDataFetchingErrors(
            { fetcherName: operationName },
            config,
            new ClientError(
              stripDataFromGraphqlResponse(response),
              requestBody
            ),
            ssrContext,
            {
              callLocation: requestHelpers?.callLocation ?? 'fetchGraphql',
              graphqlError: 'allowed',
            }
          )
        }

        leaveCustomGraphqlBreadcrumb({
          operationName,
          url: config.url,
          hasError: responseState !== 'ok',
        })
        // first data is axios, second data is graphql
        return response.data.data
      } catch (error) {
        if (axios.isCancel(error)) {
          // logging this error locally might be useful
          // eslint-disable-next-line no-console
          if (!isProduction && !isRunningTests) console.error(error)
        } else {
          logDataFetchingErrors(
            { fetcherName: operationName },
            config,
            error,
            ssrContext,
            {
              callLocation: requestHelpers?.callLocation ?? 'fetchGraphql',
              graphqlError: 'fatal',
            }
          )
        }
        throw error
      }
    }
  }
}
