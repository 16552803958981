import { datadogRum } from '@datadog/browser-rum-slim'
import { reactPlugin } from '@datadog/browser-rum-react'

if (
  process.env.NEXT_PUBLIC_ENV === 'staging' ||
  process.env.NEXT_PUBLIC_ENV === 'production'
) {
  datadogRum.init({
    applicationId: '258666b0-d235-46b0-85ba-2a5f36be52d8',
    clientToken: process.env.NEXT_PUBLIC_DATADOG_API_KEY,
    site: 'us5.datadoghq.com',
    service: 'segway',
    env: process.env.NEXT_PUBLIC_ENV,
    version:
      process.env.NEXT_PUBLIC_VERSION_TAG || // set app version to tag if present
      process.env.NEXT_PUBLIC_COMMIT_SHA || // otherwise use commit sha
      'local',
    sessionSampleRate: 100,
    defaultPrivacyLevel: 'mask-user-input',
    plugins: [reactPlugin({ router: false })],
  })
}
